<script setup lang="ts">
import { useTranslation } from 'i18next-vue'
const { i18next } = useTranslation()
</script>

<template>
    <div v-show="i18next.language == 'hu'">
        <div div="login-notice">
Belépéssel elfogadod a szabályzatot.
        </div>
        <div class="changelog">
            <h3>Frissítések</h3>
2023/11/14: 3 új terület, háttérzene a vonaton, közértben, tengerparton, hegyvidéken, többféle javítások.<br/>
2023/10/10: Többféle változás. Készitettem egy leirást a <a href="https://visualchat.hu/gikohu-mana-guide-hun.pdf" target="_blank" rel="noreferrer noopener">mana rendszerről</a>.<br/>
2023/10/06: Feljegyzéseket hagyhatsz és olvashatsz el az #olvas és #iras parancsokkal, 10 manapont használatával.<br/>
2023/10/05: A szentélyben mana pontot adományozhatsz ami 15% eséllyel megduplázza a jelenlegi pontjaid számát.<br/>
2023/10/03: Üzenhetsz egyszerre mindenkinek egy kiabálással mostantól, a parancs #kialt és utána az üzeneted, ez is 1 manába kerül.<br/>
2023/10/02: Új témák kerültek fel, jelenleg 3 sötét és 3 világos árnyalat érhető el.<br/>
2023/09/30: A távolból üzenhetsz valakinek akit kiválasztasz előzetesen a naplóban. A parancs neve #suttog, utána jön az üzenet. Jelenleg 1 manába kerül.<br/>
2023/09/29: A #teleport használatához manára van szükség amit időszakosan kapsz a szervertől amíg fenn vagy folyamatosan.<br/>
2023/05/20: Javítva a hiba, amely miatt a közvetítések nemvoltak  hallhatók iPhone-on.<br/>
2023/04/26: Javított bejelentkezési idő.<br/>
2023/04/20: Kő papír olló került az Iskolaudvarba, a Szökőkút térre és az öltözőbe.<br/>
2023/03/19: Niconico mód hozzáadva.<br/>
2023/03/04: Villogás hozzáadva.<br/>
2023/02/28: Animációk hozzáadva a Hegytetőhöz és a Cafe Vistához.<br/>
2023/02/24: Azok a szavak, amelyek értesítési hangot adnak le (például ha a „Felhasználónév-említési hang” engedélyezve van), mostantól félkövéren jelennek meg.<br/>
2022/12/11: Némító gomb hozzáadva a streamerekhez. Az asztali értesítést a rendszer mostantól minden felhasználónak elküldi abban a szobában, ahol a stream indul.<br/>
2022/12/04: Sötét téma hozzáadva.<br/>
2022/11/24: Új karakter hozzáadva.<br/>
2022/10/21: Javítva a TTS az iphone-okon.<br/>
2022/08/26: Új szoba hozzáadva. Egy lehetőség hozzáadva a napló megjelenítéséhez a bevitel felett.<br/>
2022/08/19: Új szoba hozzáadva.<br/>
2022/07/30: Új karakter hozzáadva.<br/>
2022/06/29: Javítva a hangerő-csúszka hibája. Kijavítottuk azt a mobilhibát, amely miatt a videók nem különültek el bezáráskor.<br/>
2022/06/27: Vtuber mód hozzáadva.<br/>
2022/06/26: Mostantól duplán kattinthatsz egy szoba nevére a #teleport ablakban. A stream eszköz felugró ablaka megjegyzi a legutóbbi kijelölést.<br/>
2022/06/20: Javítva néhány hiba a videofolyamok külön lapra való leválasztásával.<br/>
        </div>
<div class="rules">
<h3>Szabályok</h3>
<ul id='rules'>
<li>Regisztráció nincs és nem is lesz, tripkódot használhatsz. A neved után írj egy # szimbólumot majd egy kifejezést amit csak te ismersz.</li>
<li>Nincsenek közvetlen moderátorok és adminisztrátorok. Kérlek használd a tiltás/eltüntetés funkciókat, ha valakit problémásnak ítélsz.</li>
<li>A fentitől függetlenül a magyarországi törvények itt is hatályosak, a viselkedésért és megosztott tartalomért egyedül a felhasználó felel.</li>
<li> IP cím alapú kitiltást vonhatnak maguk után a szándékos károkozásra irányuló cselekedetek.</li>
<li> Nem tárolunk és kezelünk semmiféle személyes adatot, a tiltásokhoz és naplózáshoz szükséges információk nem köthetőek személyekhez.</li>
</ul>
</div>
<div class="links">
<h3>Linkgyűjtemény</h3>
<ul id="links">
<li>Igen, van <a href="https://visualchat.hu/gikopoi_world.jpg" target="_blank" rel="noreferrer noopener">térkép</a>!</li>
<li><a href="https://play.gikopoi.com" target="_blank" rel="noreferrer noopener">gikopoi.com</a> (gikopoi3, angol nyelvű)</li>
<li><a href="https://gikopoipoi.net" target="_blank" rel="noreferrer noopener">gikopoipoi.net</a> (gikopoi2, főleg angol és japán)</li>
<li>Linkcserével kapcsolatban a chaten érdeklődj, 88x31 button <a href='https://visualchat.hu/partner.png' rel="noreferrer noopener">itt érhető el</a> az oldalhoz.</li>
</ul>
</div>
    </div>
    <div v-show="i18next.language != 'hu'">
        <div div="login-notice">
With logging in you accept our rules.
        </div>
        <div class="changelog">
            <h3>Updates</h3>
2023/11/14: 3 new maps, background music on train, seashore, hilltop, store, some fixes.<br/>
2023/10/10: Several changes. I made a small mana guide that can be <a href="https://visualchat.hu/gikohu-mana-guide.pdf" target="_blank" rel="noreferrer noopener">read here</a>.<br/>
2023/10/06: You can leave and read written notes with the #write and #read commands for 10 mana points.<br/>
2023/10/05: In the Shrine you can now donate mana points to receive a 15% chance to double your current points.<br/>
2023/10/03: You can message the whole server with a shout, the command is #shout, then your message to send. Currently 1 mana point to use as well.<br/>
2023/10/02: We got some new themes, there are 3 dark and 3 light ones available  for now.<br/>
2023/09/30: You can message someone from the distance, after choosing them from your log window. The command is #whisper, then your message to send. Currently costs 1 mana point.<br/>
2023/09/29: To use #rula you will need mana points that you get regularly from the server as long as you stay connected.<br/>
2023/05/16: Fixed bug that made streams inaudible on iphones.<br/>
2023/04/26: Improved login time.<br/>
2023/04/06: Added Rock Paper Scissors to Schoolyard, Fountain Plaza and Dressing Room.<br/>
2023/03/19: Added Niconico mode.<br/>
2023/03/04: Added blinking.<br/>
2023/02/28: Added animations in Hilltop and Cafe Vista.<br/>
2023/02/24: Words that make a notification sound play (for example, when "Username Mention Sound" is enabled) are now displayed in bold.<br/>
2022/12/11: Added a mute button for streamers. A desktop notification is now sent to all users in the room where a stream starts.<br/>
2022/12/04: Added dark theme.<br/>
2022/11/24: Added a new character.<br/>
2022/10/21: Fixed TTS on iphones.<br/>
2022/08/26: Added new room. Added an option for displaying the chat log above the input textbox.<br/>
2022/08/19: Added new room.<br/>
2022/07/30: Added a new character.<br/>
2022/06/29: Fixed bug with the volume slider. Fixed mobile bug that didn't make videos detached to a separate get reattached to the main page when closing the tab.<br/>
2022/06/27: Added vtuber mode.<br/>
2022/06/26: Can now double click to a room name in the rula popup to move to that room. The stream device popup now remembers the last selection (only works across a single login session).<br/>
2022/06/11: Fixed some bugs with detaching video streams to a separate tab.<br/>	    

        </div>
<div class="rules">
<h3>Rules</h3>
<ul id='rules'>
<li>There is no registration and there will be no such option, you can use tripcodes after your name by putting # and a phrase only you would know.</li>
<li>There are no directly logged in administrators or moderators. Please use the block/ignore functions if someone gives you trouble.</li>
<li>Despite this, hungarian laws are effective and will be enforced here as well, your behaviour and shared contents are your own responsibility only.</li>
<li>Ban based on the user's IP address will be issued if your actions are aimed at intentionally harming either the community or the service.</li>
<li>We do not store or handle any personal information, the information necessary for blocking or keeping software logs are not personal.</li>
</ul>
</div>
<div class="links">
<h3>Links</h3>
<ul id="links">
<li>Yes, there is a <a href="https://visualchat.hu/gikopoi_world.jpg" target="_blank" rel="noreferrer noopener">map</a>!</li>
<li><a href="https://play.gikopoi.com" target="_blank" rel="noreferrer noopener">gikopoi.com</a> (gikopoi3, english)</li>
<li><a href="https://gikopoipoi.net" target="_blank" rel="noreferrer noopener">gikopoipoi.net</a> (gikopoi2, mainly english and japanese)</li>
<li>If you are looking for exchanging links, ask us on the chat. Our 88x31 button is <a href="https://visualchat.hu/partner.png" target="_blank" rel="noreferrer noopener">available here</a>.</li>
</ul>
</div>

    </div>
</template>
