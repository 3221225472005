import ja from "../langs/ja.json5";
import en from "../langs/en.json5";
import de from "../langs/de.json5";
import it from "../langs/it.json5";
import es from "../langs/es.json5";
import zh_TW from "../langs/zh-TW.json5";
import id from "../langs/id.json5";
import sv from "../langs/sv.json5";
import hu from "../langs/hu.json5";
import la from "../langs/la.json5";
import tr from "../langs/tr.json5";
import lt from "../langs/lt.json5";
import ru from "../langs/ru.json5";
import cz from "../langs/cz.json5";
import pl from "../langs/pl.json5";
import esp from "../langs/esp.json5";
import ua from "../langs/ua.json5";
import lj from "../langs/lj.json5";
import tp from "../langs/tp.json5";

export default
{
    ja: { common: ja },
    en: { common: en },
    de: { common: de },
    it: { common: it },
    es: { common: es },
    'zh-TW': { common: zh_TW },
    id: { common: id },
    sv: { common: sv },
    hu: { common: hu },
    la: { common: la },
    tr: { common: tr },
    lt: { common: lt },
    ru: { common: ru },
    pl: { common: pl },
    ua: { common: ua },
    lj: { common: lj },
    esp: { common: esp },
    tp: { common: tp },
    cz: { common: cz },
}
